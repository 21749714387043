<template>
    <div>
        <!-- <template v-if="isMobileOrPC == 'PC'">
            <pcIndex />
        </template>
        <template v-else>
            <mobileIndex />
        </template> -->
    </div>
</template>

<script>
// import mobileIndex from './Mobile/Index';
// import pcIndex from './Pc/Index';

export default {
    // components: {
    //     mobileIndex,
    //     pcIndex
    // },
    data() {
        return {
            isMobileOrPC: '',

        }
    },
    mounted() {
        let that = this
        if(this._isMobile()) {
            that.isMobileOrPC = 'Mobile'
            this.$router.push('/Mobile/Index')
            // window.onload = function() {
            //     that.getRem(750, 100)
            // };
            // window.onresize = function() {
            //     that.getRem(750, 100)
            // };
        } else {
            that.isMobileOrPC = 'PC'
            this.$router.push('/home/index')
        }
    },
    methods:{
        //判断手机端还是PC端
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        // getRem(pwidth, prem) {
        //     var html = document.getElementsByTagName("html")[0];
        //     var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
        //     html.style.fontSize = oWidth/pwidth*prem + "px";
        // }
    }
}
</script>
